<template>
  <!-- Destruction -->
  <!-- Destruction en végétation et destruction en récolte sont commune -->
  <div class="organ-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <div class="tabs-material-subtitle--button-wrapper">
          <h2>Destruction en {{ isVegetation() ? 'végétation' : 'récolte' }}</h2>

          <div class="tabs-material-subtitle--button">
            <Btn
              color="primary"
              text="Validation"
              @click="openModal('validation', fiche)"
            />
          </div>
        </div>
      </Container>
    </div>

    <Section>
      <Container>
        <Table
          :headers="headers"
          :static-headers="staticHeaders"
          :items="modalities"
          :hiddenItems="['id', 'ordre']"
          :tools="true"
        >
          <template v-slot:tools="{ item }">
            <Btn
              icon="create"
              color="white"
              title="Modifier la modalité"
              @click="openModal('editModality', item.item)"
            />
          </template>
        </Table>
      </Container>
    </Section>
  </div>

  <!-- Modal Validation de la destruction -->
  <Modal
    title="Validation de la destruction"
    :active="modal.validation"
    :data="modalData"
    :containForm="true"
    :modalValidationSchema="validationSchema"
    @modal-close="modal.validation = false"
    @modal-form-submitted="handleValidation($event)"
  >
    <template v-slot:modal-body>
      <SelectExtended
        id="moment_destruction_id"
        :apiParams="{ sort: 'valeur.ASC' }"
        optionKey="id"
        optionValue="valeur"
        label="Moment de destruction"
        :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'momentdestruction'
            )}/valeur`"
      />
      <Input id="trigramme_validation" label="Trigramme" />
      <Input id="date_validation" label="Date" type="date" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.validation = false" />
      <Btn text="Valider" color="primary" type="submit" />
    </template>
  </Modal>

  <!-- Édition d'une modalité -->
  <Modal
    title="Édition de la modalité"
    :active="modal.editModality"
    :data="modalData"
    :containForm="true"
    :modalValidationSchema="modalitySchema"
    @modal-close="modal.editModality = false"
    @modal-form-submitted="handleModality(modalData.id, $event)"
  >
    <template v-slot:modal-body>
      <template v-if="isVegetation()">
        <p class="h4">Mécanique</p>
        <Input id="mecanique_date" label="Date" type="date" />
        <Input id="mecanique_moyen" label="Moyen"  />
        <p class="h4">Chimique</p>
        <Input id="chimique_date" label="Date" type="date" />
        <Input id="chimique_produit" label="Produit" />
        <Input
          id="chimique_dose"
          label="Dose"
          type="number"
          :options="{ min: 0, step: 0.1 }"
        />
        <SelectExtended
          id="unite_uid"
          :apiParams="{ sort: 'valeur.ASC' }"
          optionKey="uid"
          optionValue="valeur"
          label="Unité"
          apiEndpoint="unite"
        />
      </template>
      <template v-else>
        <Input id="quantite" label="Quantité" type="number" />
      </template>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.editModality = false" />
      <Btn text="Éditer" color="primary" type="submit" />
    </template>
  </Modal>

  <div class="view-footer">
    <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
  </div>
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Table from '@/components/table/Table.vue'

export default {
  name: 'DestructionSubview',

  components: {
    Table,
    SelectExtended,
    Input,
    Modal,
    Container,
    Section,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      moment_destruction_id: this.yup.object().nullable(),
      trigramme_validation: this.yup.string()
        .min(3, 'Le trigramme doit contenir 3 caractères')
        .max(3, 'Le trigramme doit contenir 3 caractères')
        .nullable(),
      date_validation: this.yup.date().nullable(),
    })

    const modalitySchema = this.yup.object().shape({
      mecanique_moyen: this.yup.string().nullable(),
      mecanique_date: this.yup.date().nullable(),
      chimique_produit: this.yup.string().nullable(),
      chimique_dose: this.yup.number().nullable(),
      chimique_date: this.yup.date().nullable(),
      unite_uid: this.yup.object().nullable(),
      quantite: this.yup.number().nullable(),
    })

    return {
      fiche: {},
      modal: {
        destructionProof: false,
        deleteDestructionProof: false,
        validation: false,
        editModality: false,
      },
      modalData: {},
      headers: [],
      staticHeaders: [],
      modalities: {},
      componentKey: 0,
      validationSchema,
      modalitySchema,
    }
  },

  watch: {
    '$route.name': {
      deep: true,
      handler() {
        if (typeof this.$route.params.tid !== 'undefined') {
          this.setComponent()
        }
      },
    },
  },

  mounted() {
    this.getFiche()
    this.getDestructionProof()
    this.setComponent()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = JSON.parse(JSON.stringify(data))

      if (modal === 'editModality') {
        if (this.isVegetation()) {
          if (this.modalData.mecanique_date) {
            // eslint-disable-next-line no-param-reassign
            this.modalData.mecanique_date = this.modalData.mecanique_date
              .split(' ')
              .shift()
          }
          if (this.modalData.chimique_date) {
            // eslint-disable-next-line no-param-reassign
            this.modalData.chimique_date = this.modalData.chimique_date
              .split(' ')
              .shift()
          }

          const findUnite = this.modalities.find((modality) => modality.id === this.modalData.id)
          if (findUnite) {
            // eslint-disable-next-line no-param-reassign
            this.modalData.unite_uid = {
              key: findUnite.unite?.uid,
              value: findUnite.unite?.valeur,
            }
          }
        }
      }

      if (modal === 'validation') {
        if (data.moment_destruction) {
          // eslint-disable-next-line no-param-reassign
          data.moment_destruction_id = {
            key: data.moment_destruction.id,
            value: data.moment_destruction.valeur,
          }
        }
        if (data.date_validation) {
          // eslint-disable-next-line no-param-reassign
          data.date_validation = data.date_validation
            .split(' ')
            .shift()
        }
      }

      this.modal[modal] = true
    },

    async setComponent() {
      const { modalities, headers } = await this.taskService.initializeComponent(this.$route)

      this.modalities = modalities
      this.headers = headers
    },

    // GETTERS
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'destruction')
    },

    getDestructionProof() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/preuve`)
        .then((response) => {
          const data = response.data[0]
          this.proof = data
        })
    },

    // FORM
    /**
     * Edit validation
     *
     * @param values
     */
    handleValidation(values) {
      this.emitter.emit('open-loader')

      // eslint-disable-next-line no-param-reassign
      values.moment_destruction_id = values?.moment_destruction_id?.key ?? null
      // eslint-disable-next-line no-param-reassign
      values.date_validation = this.helperService.formatDateForApi(values.date_validation) ?? null

      this.fetchService
        .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/${this.fiche.id}`, values)
        .then(() => {
          this.getFiche()

          this.emitter.emit('alert', {
            type: 'success',
            content: 'La validation a bien été modifiée.',
          })
        })

      this.modal.validation = false
      this.emitter.emit('close-loader')
    },

    /**
     * Edit modality
     *
     * @param id
     * @param values
     */
    handleModality(id, values) {
      this.emitter.emit('open-loader')

      let params = values
      if (this.isVegetation()) {
        params.unite_uid = values?.unite_uid?.key ?? null
        params.mecanique_date = this.helperService.formatDateForApi(values.mecanique_date) ?? null
        params.chimique_date = this.helperService.formatDateForApi(values.chimique_date) ?? null
        delete params.quantite
      } else {
        params = (({ quantite }) => ({ quantite }))(params) // Keep only the 'quantite' key
      }

      this.fetchService
        .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/modalite/${id}`, params)
        .then(() => {
          this.setComponent()

          this.emitter.emit('alert', {
            type: 'success',
            content: 'La modalité a bien été modifiée.',
          })
        })

      this.modal.editModality = false
      this.emitter.emit('close-loader')
    },

    /**
     * Delete destruction proof
     */
    deleteDestructionProof() {
      this.emitter.emit('open-loader')
      this.fetchService
        .delete(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/preuve/${this.proof.id}`)
        .then(
          () => {
            this.getDestructionProof()
            this.proofMedia = null
            this.componentKey += 1

            this.emitter.emit('alert', {
              type: 'success',
              content: 'La preuve de destruction a bien été supprimée.',
            })
          },
        )

      this.modal.deleteDestructionProof = false
      this.modal.destructionProof = false
      this.emitter.emit('close-loader')
    },

    // HELPERS

    /**
     * Check if destruction is vegetation
     * @returns {boolean}
     */
    isVegetation() {
      return this.$route.name.includes('Vegetation')
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-material-subtitle--button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabs-material-subtitle--button {
  display: flex;
  gap: $gutter-half;
}
</style>
